body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #245083;
}

.logo {
  height: 50%;
  width: 50%;
}

a:visited {
  color: #245083;
}

.socials {
  height: 2em;
}
